import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  status: "idle",
  statusGetPersonalFilters: "idle",
  personalFilters: [],
  hasNextPage: null,
  page: 1,
};

export const savePersonalFilter = createAsyncThunk(
  "filters/savePersonalFilter",
  async (body) => {
    let data;
    const { optional, ...rest } = body?.meta;
    const meta = optional?.length === 0 ? rest : body?.meta;
    const { staticProp, ...rest2 } = meta;
    const result = staticProp?.trim() !== "" ? meta : rest2;
    try {
      const response = await axios.post(
        `websites/${body?.websiteID}/personal-conversation-filters`,
        result
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      console.log(error);
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const getPersonalFilters = createAsyncThunk(
  "filters/getPersonalFilters",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body?.websiteID}/personal-conversation-filters?page=${body?.page}&limit=5`
      );
      data = response.data.data.docs;
      if (response.status === 200) {
        return { data, hasNextPage: response.data.data.meta?.hasNextPage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      console.log(error);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deletePersonalFilter = createAsyncThunk(
  "filters/deletePersonalFilter",
  async (body) => {
    let data;
    try {
      const response = await axios.delete(
        `websites/${body.websiteID}/personal-conversation-filters/${body.filterID}`
      );
      data = response.data;
      if (response.status === 200) {
        return body.filterID;
      }
      throw new Error(response.statusText);
    } catch (error) {
      console.log(error);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {},
  extraReducers: {
    [savePersonalFilter.pending]: (state) => {
      state.status = "loading";
    },
    [savePersonalFilter.fulfilled]: (state, action) => {
      state.status = "success";
      state.personalFilters = [action.payload.data, ...state.personalFilters];
    },
    [savePersonalFilter.rejected]: (state) => {
      state.status = "failed";
    },
    [getPersonalFilters.pending]: (state, action) => {
      action.meta.arg.page === 1
        ? (state.statusGetPersonalFilters = "loading")
        : (state.statusGetPersonalFilters = "pending");
    },
    [getPersonalFilters.fulfilled]: (state, action) => {
      state.statusGetPersonalFilters = "success";
      state.personalFilters = state.personalFilters.concat(
        action.payload?.data
      );
      action.payload.hasNextPage && (state.page = state.page + 1);
      state.hasNextPage = action.payload.hasNextPage;
    },
    [getPersonalFilters.rejected]: (state) => {
      state.statusGetPersonalFilters = "failed";
    },
    [deletePersonalFilter.pending]: (state, action) => {
      state.statusDeletePersonalFilters = action.meta.arg.filterID;
    },
    [deletePersonalFilter.fulfilled]: (state, action) => {
      state.statusDeletePersonalFilters = "success";
      state.personalFilters = state.personalFilters.filter(
        (personalFilter) => personalFilter?._id !== action.payload
      );
    },
    [deletePersonalFilter.rejected]: (state) => {
      state.statusDeletePersonalFilters = "failed";
    },
  },
});
export const {} = slice.actions;
export const reducer = slice.reducer;
export default slice;
